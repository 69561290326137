import { Component, OnInit } from '@angular/core';
import { DailyDeposit, DepositConfiguration, PaymentFile, PaymentType } from 'xero-dental-deposit-common';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CompletedTransaction } from 'xero-dental-deposit-common';
import { AuthService } from '../auth-service/auth.service';
import { switchMap, take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-deposit-detail',
  templateUrl: './deposit-detail.component.html',
  styleUrls: ['./deposit-detail.component.css'],
})

export class DepositDetailComponent implements OnInit {
  public dailyDeposit: DailyDeposit
  public refundAmount = 0.0
  public completedTransactions: CompletedTransaction[] = []
  public paymentFile: PaymentFile
  public paymentForm: UntypedFormGroup
  public paymentTypes: PaymentType[]
  public configurations: DepositConfiguration[];

  constructor(private fb: UntypedFormBuilder, private data: DataService, private auth: AuthService, private route: ActivatedRoute, private router: Router) { }

  public linkForTransaction(id: string) : Observable<string> { 
    return this.auth.selectedTenant.pipe(switchMap((tenant) => { 
      const link = `https://go.xero.com/organisationlogin/default.aspx?shortcode=${tenant.shortCode}&redirecturl=/Bank/ViewTransaction.aspx?bankTransactionID=${id}`
      return of(link)
    }))
  }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');

      this.data.clientConfiguration.subscribe((config) => {

        this.data.obtainPaymentFileById(id).subscribe((paymentFile: PaymentFile) => {
          this.paymentTypes = config.paymentTypes
          this.completedTransactions = paymentFile.completedTransactions || []

          const paymentFormGroup = paymentFile.payments.map((payment, index) => {
            const group = this.fb.group({ 'amount': payment.amount, 'checkNumber': payment.checkNumber, 'description': payment.description, 'memo': payment.memo, 'paymentTypeId': payment.paymentTypeId })
            
            if (this.completedTransactions && this.completedTransactions.length > 0) { 
              group.disable();
            }

            group.get('paymentTypeId').valueChanges.subscribe((newPaymentTypeId) => {
              this.data.setPaymentType(id, index, newPaymentTypeId)
            })

            return group;
          })

          this.paymentForm = this.fb.group({
            'payments': this.fb.array(paymentFormGroup)
          })

          this.data.obtainDepositById(id).subscribe((dailyDeposit) => {
            this.configurations = Array.from(dailyDeposit.depositMap.keys());
            this.dailyDeposit = dailyDeposit;
            this.refundAmount = 0;

            for (const refundCheck of this.dailyDeposit.checksToWrite) {
              this.refundAmount += refundCheck.amount;
            }
          });
        });
      })


  }
}
