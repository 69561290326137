<div class="action-column">
    <button class="btn btn-success btn-sm" [disabled]="processing" (click)="processDeposit(deposit)">Process</button>
    <button [disabled]="!deposit.needsDepositSlips()" class="btn btn-success btn-sm"
        (click)="printDepositSlips(deposit)">Print
        Deposit Slips</button>
    <button *ngIf="showDelete" class="btn btn-success btn-sm" (click)="deleteDeposit(deposit)">Delete</button>
    <button *ngIf="showMove && !deposit.dateProcessed" class="btn btn-success btn-sm" (click)="markAsProcessed(deposit)">Move to
        Completed</button>
    <button *ngIf="showMove && deposit.dateProcessed" class="btn btn-success btn-sm" (click)="unmarkAsProcessed(deposit)">Move to
        Pending</button>
</div>