/**
 *
 * This class represents all the deposit configurations. A deposit configuration
 * is a typically a single transaction entered into Xero. Each deposit configration
 * will have one of the ES payment types.
 *
 */
export class DepositConfigurations {
    constructor() {
        this.depositConfigurations = [];
    }
    containsPaymentMethod(paymentMethod) {
        return this.depositConfigurations.find((depositConfiguration) => depositConfiguration.containsPaymentMethod(paymentMethod)) !== undefined;
    }
    getUsedPaymentMethods() {
        const usedMethods = [];
        for (const depositConfiguration of this.depositConfigurations) {
            usedMethods.push(...depositConfiguration.getUsedPaymentMethods());
        }
        return usedMethods;
    }
    getDepositConfiguration(paymentMethod) {
        return this.depositConfigurations.find((depositConfiguration) => depositConfiguration.containsPaymentMethod(paymentMethod));
    }
    /**
     * Add a deposit configuration.
     *
     * @param config the configuration to add.
     */
    addDepositConfiguration(config) {
        config.paymentMethodConfigurations.forEach((newDepositItemConfiguration) => {
            if (this.containsPaymentMethod(newDepositItemConfiguration.paymentMethod)) {
                throw new Error(`Duplicate payment type '${newDepositItemConfiguration.paymentMethod.name}'. It already exists in deposit configuration '${config.memo}'.`);
            }
        });
        this.depositConfigurations.push(config);
    }
}
