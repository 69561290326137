import { Component, OnDestroy, OnInit, Output } from '@angular/core';
import { DataService } from '../../data.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth-service/auth.service';
import { take, switchMap } from 'rxjs/operators';

export interface DataResponse {
  status: string;
  message: string;
  data: string
}

export interface XeroSetupResponse {
  status: string;
  message: string;
}

@Component({
  selector: 'app-xerosetup',
  templateUrl: './xero-setup.component.html',
  styleUrls: ['./xero-setup.component.css']
})

export class XeroSetupComponent {
  message: string;
  success: boolean;
  enabled = true;

  public constructor(private data: DataService, private http: HttpClient, private authService: AuthService) {

  }

  async setupXero() {
    this.enabled = false;
    this.message = 'Contacting Xero...';

      this.http.get<DataResponse>(`/api/obtainConsentUrl`).subscribe((data) => {
        if (data.status === 'OK') {
          window.location.href = data.data
        }
      },
      (error) => {
        this.message = error.error.message;
        console.log(error);
      }
    );
  }
}
