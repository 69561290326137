import { Component } from '@angular/core';
import { AuthService } from '../auth-service/auth.service';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent {
  constructor(public auth: AuthService, public data: DataService) {

  }
}
