/**
 *
 * Represent a configuration PaymentMethod part of a DepositConfiguration. This
 * class wraps the PaymentMethod with a Maximum number of times that payment
 * method can appear.
 *
 */
export class PaymentMethodConfiguration {
    constructor(init) {
        Object.assign(this, init);
    }
    static sortFn(a, b) {
        return a.paymentMethod.name < b.paymentMethod.name ? -1 : a.paymentMethod.name > b.paymentMethod.name ? 1 : 0;
    }
    toString() {
        return this.description();
    }
    description() {
        if (this.maxItems > 0) {
            return this.paymentMethod.name + ' (max ' + this.maxItems + ')';
        }
        else {
            return this.paymentMethod.name;
        }
    }
    static fromJSON(d) {
        var o = Object.assign(new PaymentMethodConfiguration({}), d);
        return o;
    }
}
