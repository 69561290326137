import { NgModule } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { PaytypeMappingComponent } from './components/setup/paytype-mapping/paytype-mapping.component';
import { DataService } from './data.service';
import { HomeComponent } from './components/home/home.component';
import { DepositsComponent } from './components/deposits/deposits.component';
import { AuthGuard } from './components/auth-service/auth.service';
import { DepositDetailComponent } from './components/deposit-detail/deposit-detail.component';
import { XeroSetupComponent } from './components/xero-setup/xero-setup.component';
import { DepositConfigurationListComponent } from './components/setup/deposit-configuration-list/deposit-configuration-list.component';
import { RefundMappingComponent } from './components/setup/refund-mapping/refund-mapping.component';
import { OAuthCallbackComponentComponent } from './oauth-callback-component/oauth-callback-component.component';
import { TokenComponent } from './components/token/token.component';
import { DepositConfigurationEditComponent, DepositConfigurationResolver, NewDepositConfigurationResolver } from './components/setup/deposit-configuration-edit/deposit-configuration-edit.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
  },

  {
    path: 'oauth-callback',
    component: OAuthCallbackComponentComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },

  {
    path: 'deposits',
    component: DepositsComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard, DataService]
  },

  {
    path: 'deposit/:id',
    component: DepositDetailComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard, DataService]
  },

  {
    path: 'setup',
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    children: [
      {
        path: 'token',
        component: TokenComponent,
        canLoad: [AuthGuard],
        canActivate: [AuthGuard,]
      },

      {
        path: 'deposits',
        component: DepositConfigurationListComponent,
        canLoad: [AuthGuard],
        canActivate: [AuthGuard],
      },
      {
        path: 'deposits/new',
        component: DepositConfigurationEditComponent,
        resolve: {
          'deposit': NewDepositConfigurationResolver
        },
        canLoad: [AuthGuard],
        canActivate: [AuthGuard, DataService]
      },
      {
        path: 'deposit/:id',
        component: DepositConfigurationEditComponent,
        resolve: {
          'deposit': DepositConfigurationResolver
        },
        canLoad: [AuthGuard],
        canActivate: [AuthGuard, DataService]
      },

      {
        path: 'refunds',
        component: RefundMappingComponent,
        canLoad: [AuthGuard],
        canActivate: [AuthGuard]
      },

      {
        path: 'xero',
        component: XeroSetupComponent,
        canLoad: [AuthGuard],
        canActivate: [AuthGuard]
      },

      {
        path: 'paytypes',
        component: PaytypeMappingComponent,
        canLoad: [AuthGuard],
        canActivate: [AuthGuard]

      },
    ]

  },

  { path: '**', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
