export var CheckGoesTo;
(function (CheckGoesTo) {
    CheckGoesTo["RESPONSIBLE_PARTY"] = "responsibleParty";
    CheckGoesTo["INSURANCE_COMPANY"] = "insuranceCompany";
})(CheckGoesTo || (CheckGoesTo = {}));
export class AdjustmentType {
    constructor(adjustmentType) {
        this.id = adjustmentType.id;
        this.name = adjustmentType.name;
    }
    static fromJSON(d) {
        var o = Object.assign(new AdjustmentType({}), d);
        return o;
    }
}
export class PaymentType {
    constructor(paytype) {
        this.id = paytype.id;
        this.name = paytype.name;
    }
    toString() {
        return "PaymentType: id=" + this.id + ",name=" + this.name;
    }
    static fromJSON(d) {
        var o = Object.assign(new PaymentType({}), d);
        return o;
    }
}
export class Contact {
    constructor(contact) {
        Object.assign(this, contact);
    }
    static fromJSON(d) {
        var o = Object.assign(new Contact({}), d);
        return o;
    }
}
export class Account {
    constructor(account) {
        Object.assign(this, account);
    }
}
export class PaymentMethod {
    constructor(paymentMethod) {
        Object.assign(this, paymentMethod);
    }
    static fromJSON(d) {
        var o = Object.assign(new PaymentMethod({}), d);
        return o;
    }
}
export class PayTypeMapping {
    constructor(paytypeMapping) {
        Object.assign(this, paytypeMapping);
    }
    static fromJSON(d) {
        var o = new PayTypeMapping(d);
        return o;
    }
}
export class DepositLine {
    constructor(depositLine) {
        Object.assign(this, depositLine);
    }
    static fromJSON(d) {
        var o = Object.assign(new DepositLine({}), d);
        return o;
    }
    toString() {
        return `DepositLine: amount=${this.amount}, paymentType=${this.paymentType.name}, paymentMethod=${this.paymentMethod.name}, memo='${this.memo}' `;
    }
}
export class BankAccount extends Account {
    static fromJSON(d) {
        var o = Object.assign(new BankAccount({}), d);
        return o;
    }
}
export class IncomeAccount extends Account {
    static fromJSON(d) {
        var o = Object.assign(new IncomeAccount({}), d);
        return o;
    }
}
