import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { PaytypeMappingComponent } from './components/setup/paytype-mapping/paytype-mapping.component';
import { DataService } from './data.service';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HomeComponent } from './components/home/home.component';
import { DepositsComponent } from './components/deposits/deposits.component';
import { environment } from '../environments/environment';
import { AuthService, AuthGuard } from './components/auth-service/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app'
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbNavModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MomentModule } from 'ngx-moment'
import { DepositDetailComponent } from './components/deposit-detail/deposit-detail.component';
import { XeroSetupComponent } from './components/xero-setup/xero-setup.component';
import { DepositConfigurationFormComponent } from './components/setup/deposit-configuration-form/deposit-configuration-form.component';
import { DepositConfigurationListComponent } from './components/setup/deposit-configuration-list/deposit-configuration-list.component';
import { RefundMappingComponent } from './components/setup/refund-mapping/refund-mapping.component';
import { OAuthCallbackComponentComponent } from './oauth-callback-component/oauth-callback-component.component';
import { AppRoutingModule } from './app-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { SelectXeroTenantComponent } from './select-xero-tenant/select-xero-tenant.component';
import { TokenComponent } from './components/token/token.component';
import { DepositConfigurationEditComponent, DepositConfigurationResolver, NewDepositConfigurationResolver } from './components/setup/deposit-configuration-edit/deposit-configuration-edit.component';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideDatabase, getDatabase } from '@angular/fire/database';
import { DepositActionComponentComponent } from './deposit-action-component/deposit-action-component.component';

@NgModule({
  declarations: [
    PaytypeMappingComponent,
    DepositConfigurationEditComponent,
    DepositConfigurationFormComponent,
    DepositConfigurationListComponent,
    RefundMappingComponent,
    OAuthCallbackComponentComponent,
    HomeComponent,
    DepositsComponent,
    DepositDetailComponent,
    XeroSetupComponent,
    DepositActionComponentComponent,
    AppComponent,
    SelectXeroTenantComponent,
    TokenComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NgbTooltipModule,
    NgbNavModule,
    NgbAccordionModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    NavbarComponent,
    MomentModule.forRoot(),
    ToastrModule.forRoot(),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideDatabase(() => getDatabase()),
    provideAuth(() => getAuth())
  ],
  providers: [DataService, AuthService, AuthGuard, CookieService, NewDepositConfigurationResolver, DepositConfigurationResolver],
  bootstrap: [AppComponent]
})
export class AppModule {
}
