<ng-template #showloading>
  <h2>Loading...</h2>
</ng-template>

<form *ngIf="!loading" [formGroup]="depositForm" (ngSubmit)="onSubmit()" novalidate>
  <h2>Deposit Detail</h2>
  <div class="row">
    <div class="col-md-3">
      <div class="form-group">
        <label for="memo" class="form-label">Memo:</label>
        <input id="memo" class="form-control" formControlName="memo"
          [ngClass]="{ 'is-invalid': submitted && depositForm.controls.memo.errors }">
        <div *ngIf="submitted && depositForm.controls.memo.errors" class="invalid-feedback">
          <div *ngIf="depositForm.controls.memo.errors.required">Memo is required</div>
        </div>
      </div>
      <div class="form-check">
        <input type="checkbox" class="form-check-input" value="" id="useFirstPaymentDescriptionAsMemo"
          formControlName="useFirstPaymentDescriptionAsMemo">
        <label class="form-check-label" for="useFirstPaymentDescriptionAsMemo">First Payment Description as Memo</label>
      </div>
      <div class="form-group">
        <label class="form-label" for="depositToAccount">Deposit To Bank Account:</label>
        <select id="depositToAccount" class="form-control" formControlName="depositToAccount"
          [compareWith]="bankAccountCompare"
          [ngClass]="{ 'is-invalid': submitted && depositForm.controls.depositToAccount.errors }">
          <option [ngValue]="bankAccount" *ngFor="let bankAccount of bankAccounts">{{ bankAccount.name }}</option>
        </select>
        <div *ngIf="submitted && depositForm.controls.depositToAccount.errors" class="invalid-feedback">
          <div *ngIf="depositForm.controls.depositToAccount.errors.required">Must select a deposit to account</div>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label" for="contact">Contact for Deposit</label>
        <select class="form-control" formControlName="contact" [compareWith]="contactCompare"
          [ngClass]="{ 'is-invalid': submitted && depositForm.controls.contact.errors }">
          <option [ngValue]="contact" *ngFor="let contact of contacts">{{ contact.name }}</option>
        </select>
        <div *ngIf="submitted && depositForm.controls.depositToAccount.errors" class="invalid-feedback">
          <div *ngIf="depositForm.controls.contact.errors.required">Must select a contact</div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <h3>Available Payment Methods</h3>
      <div class="row">
        <div class="col-md">
          Name
        </div>
        <div class="col-md">
          Actions
        </div>
      </div>
      <div class="row payment-method-row"
        *ngFor="let availablePaymentMethodConfiguration of availablePaymentMethodConfigurations">
        <div class="col-md">{{ availablePaymentMethodConfiguration.paymentMethod.name }}</div>
        <div class="col-md"><button type="button" class="btn btn-primary"
            (click)="addPaymentMethodConfiguration(availablePaymentMethodConfiguration)">Add</button></div>
      </div>
    </div>
    <div class="col-md-6">
      <h3>Configured Payment Methods</h3>
      <div class="row">
        <div class="col-md-4">
          Name
        </div>
        <div class="col-md-4">
          Max Items
        </div>
        <div class="col-md-4">
          Actions
        </div>
      </div>
      <div class="row"
        *ngFor="let paymentMethodConfigurationsFormGroup of depositForm.controls.paymentMethodConfigurations.controls; let i = index">
        <div class="col-md-4">{{ paymentMethodConfigurationsFormGroup.value.paymentMethod.name }}</div>
        <div class="col-md-4" formArrayName="paymentMethodConfigurations">
          <div [formGroup]="paymentMethodConfigurationsFormGroup">
            <select formControlName="maxItems" class="form-control">
              <option [ngValue]="0">No Limit</option>
              <option [ngValue]="1">1</option>
              <option [ngValue]="2">2</option>
              <option [ngValue]="3">3</option>
              <option [ngValue]="4">4</option>
              <option [ngValue]="5">5</option>
              <option [ngValue]="6">6</option>
              <option [ngValue]="7">7</option>
              <option [ngValue]="8">8</option>
              <option [ngValue]="9">9</option>
              <option [ngValue]="10">10</option>
              <option [ngValue]="11">11</option>
              <option [ngValue]="12">12</option>
              <option [ngValue]="13">13</option>
              <option [ngValue]="14">14</option>
              <option [ngValue]="15">15</option>
              <option [ngValue]="16">16</option>
              <option [ngValue]="17">17</option>
              <option [ngValue]="18">18</option>
              <option [ngValue]="19">19</option>
              <option [ngValue]="20">20</option>
            </select>
          </div>
        </div>
        <div class="col-md-4">
          <button type="button" class="btn-sm btn btn-primary"
            (click)="removePaymentMethodConfiguration(paymentMethodConfigurationsFormGroup.value, i)">Remove</button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="submitted && depositForm.controls.paymentMethodConfigurations.errors" class="alert alert-danger">
    <div *ngIf="depositForm.controls.paymentMethodConfigurations.errors.mustHaveOneConfiguredPaymentMethod">Must have
      at least one payment method configured. Add an available Payment Method above.</div>
  </div>
  <button type="submit" [disabled]="depositForm.pristine" class="btn btn-success">Save</button> &nbsp;
  <button type="button" style="cursor: not-allowed" (click)="onCancel()" class="btn btn-danger">Cancel</button>
</form>