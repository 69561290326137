export class CheckToWrite {
    static fromJSON(json) {
        const checkToWrite = new CheckToWrite();
        checkToWrite.id = json.id;
        checkToWrite.amount = json.amount;
        checkToWrite.fullName = json.fullName;
        checkToWrite.address1 = json.address1;
        checkToWrite.address2 = json.address2;
        checkToWrite.city = json.city;
        checkToWrite.state = json.state;
        checkToWrite.zipcode = json.zipcode;
        checkToWrite.memo = json.memo;
        checkToWrite.adjustmentType = { id: json.adjustmentType.id, name: json.adjustmentType.name };
        return checkToWrite;
    }
}
