import {Component, OnDestroy, OnInit} from '@angular/core';
import {DataService} from '../../../data.service';
import { ClientConfiguration, DepositConfiguration } from 'xero-dental-deposit-common'
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-deposit-configuration-list',
  templateUrl: './deposit-configuration-list.component.html',
  styleUrls: ['./deposit-configuration-list.component.css']
})

export class DepositConfigurationListComponent implements OnInit {
  clientConfiguration: BehaviorSubject<ClientConfiguration>;
  hasUnconfiguredPaymentTypes: boolean;

  constructor(public db: DataService, public router: Router) {
  }

  public editDeposit(depositToEdit: DepositConfiguration) {
    this.router.navigate(['/setup/deposit/', depositToEdit.id]);
  }

  public ngOnInit() {
    this.clientConfiguration = this.db.clientConfiguration;
    this.clientConfiguration.pipe(filter((x) => x !== undefined)).subscribe( (value) => {
      this.hasUnconfiguredPaymentTypes = value.hasUnconfiguredPaymentMethods();
    });
  }

  public newDeposit() {
    this.router.navigate(['/setup/deposits/new']);
  }

  public deleteDeposit(depositToDelete: DepositConfiguration) {
    this.db.deleteDepositConfiguration(depositToDelete);
  }
}
