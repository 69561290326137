import {Component, OnInit, Injectable} from '@angular/core';
import { DepositConfiguration, Deposit } from 'xero-dental-deposit-common';
import { ActivatedRoute, Router, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DataService } from '../../../data.service';
import { take} from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable()
export class NewDepositConfigurationResolver implements Resolve<DepositConfiguration> {
  constructor(private db: DataService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DepositConfiguration> {
      return of(new DepositConfiguration());
  }
}

@Injectable()
export class DepositConfigurationResolver implements Resolve<DepositConfiguration> {
    constructor(private db: DataService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DepositConfiguration> {
        const id: string = route.params['id'];
        return this.db.getDepositConfiguration(id).pipe(take(1));
    }
}

@Component({
  selector: 'app-deposit-configuration-edit',
  templateUrl: './deposit-configuration-edit.component.html',
  styleUrls: ['./deposit-configuration-edit.component.css']
})
export class DepositConfigurationEditComponent implements OnInit {
  deposit: DepositConfiguration;

  onCancel() {
    this.router.navigate(['/setup/deposits']);
  }

  onSave(deposit: DepositConfiguration) {
    this.router.navigate(['/setup/deposits']);
    this.data.setDepositConfiguration(deposit).then(() => {
      this.router.navigate(['/setup/deposits']);
    }).catch((error) => {
      console.log(`Error while attempting to save deposit: ${error}`);
    });
  }

  constructor(private router: Router, private data: DataService,  private route: ActivatedRoute) {
    console.log("hello")
  }

  async ngOnInit() {
    this.deposit = this.route.snapshot.data['deposit'];
  }
}
