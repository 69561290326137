<div *ngIf="recentDeposits; else loading">
    <h2>Pending Deposits</h2>
    <ng-template #nopending>
        <h3>No Pending deposits available</h3>
    </ng-template>

    <div *ngIf="pendingDeposits().length>0; else nopending">
        <div class="row">
            <div class="col">End of Day</div>
            <div class="col">Deposit Total</div>
            <div class="col">Refund Checks</div>
            <div class="col-md-6">Actions</div>
        </div>
        <div class="row" *ngFor="let deposit of pendingDeposits()">

            <div class="col"> <a [routerLink]="['/deposit',deposit.id]">{{ deposit.date }}</a></div>
            <div class="col">{{ deposit.total | currency }}</div>
            <div class="col">
                <span *ngIf="deposit.checksToWrite.length === 0">None</span>
                <span *ngIf="deposit.checksToWrite.length>0">{{ deposit.checksToWrite.length }} Refund Checks</span>
            </div>
            <div class="col-md-6 action-column">
                <app-deposit-action-component [deposit]="deposit"></app-deposit-action-component>
            </div>
        </div>
    </div>

    <h2>Completed Deposits</h2>

    <ng-template #nocompleted>
        <h3>No completed deposits available</h3>
    </ng-template>

    <div *ngIf="completedDeposits().length>0; else nocompleted">
        <div class="row">
            <div class="col">End of Day</div>
            <div class="col">Deposit Total</div>
            <div class="col">Refund Checks</div>
            <div class="col">Date Processed</div>
            <div class="col-md-6">Actions</div>
        </div>
        <div class="row" *ngFor="let deposit of completedDeposits()">
            <div class="col"> <a [routerLink]="['/deposit',deposit.id]">{{ deposit.date }}</a></div>
            <div class="col">{{ deposit.total | currency }}</div>
            <div class="col">
                <span *ngIf="deposit.checksToWrite.length === 0">None</span>
                <span *ngIf="deposit.checksToWrite.length>0">{{ deposit.checksToWrite.length }} Refund Checks</span>
            </div>
            <div class="col">{{ deposit.dateProcessed | amTimeAgo }}</div>
            <div class="col-md-6 action-column">
                <app-deposit-action-component [deposit]="deposit"></app-deposit-action-component>
            </div>
        </div>
    </div>
</div>

<ng-template #loading>
    <h2>Loading...</h2>
</ng-template>