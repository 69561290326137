import { PaymentMethodConfiguration } from './PaymentMethodConfiguration.js';
export class DepositConfiguration {
    constructor(init) {
        this.paymentMethodConfigurations = [];
        Object.assign(this, init);
    }
    addPaymentMethodConfiguration(config) {
        this.paymentMethodConfigurations.push(config);
    }
    getPaymentMethodMax(paymentMethod) {
        const paymentMethodConfiguration = this.paymentMethodConfigurations.find((item) => item.paymentMethod.id === paymentMethod.id);
        if (paymentMethodConfiguration === undefined) {
            throw new Error(`payment method ${paymentMethod.name} does not exist in this deposit ${this.memo}`);
        }
        return paymentMethodConfiguration.maxItems || 0;
    }
    /**
     *
     * Returns true if this deposit configuration contains the specified Payment Method.
     *
     */
    containsPaymentMethod(paymentMethod) {
        return this.paymentMethodConfigurations.find((paymentMethodConfiguration) => paymentMethodConfiguration.paymentMethod.id === paymentMethod.id) !== undefined;
    }
    getUsedPaymentMethods() {
        return this.paymentMethodConfigurations.map(depositItem => depositItem.paymentMethod);
    }
    toString() {
        return this.getDescription();
    }
    getDescription() {
        return this.paymentMethodConfigurations.map(depositItem => depositItem.description()).join(', ');
    }
    static fromJSON(d) {
        var o = Object.assign(new DepositConfiguration({}), d);
        o.paymentMethodConfigurations = [];
        if (o.depositToAccount === undefined) {
            throw new Error(`Cannot locate BankAccount with id ${o.depositToAccount.id} when configuring DepositConfiguration ${o.memo}`);
        }
        if (o.contact === undefined) {
            throw new Error(`Cannot locate Contact with id ${o.contact.id} when configuring DepositConfiguration ${o.memo}`);
        }
        d.paymentMethodConfigurations.forEach((paymentMethodConfiguration) => {
            o.paymentMethodConfigurations.push(PaymentMethodConfiguration.fromJSON(paymentMethodConfiguration));
        });
        return o;
    }
}
