import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-select-xero-tenant',
  templateUrl: './select-xero-tenant.component.html',
  styleUrls: ['./select-xero-tenant.component.css']
})
export class SelectXeroTenantComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
