import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { BankAccount, Contact, PaymentMethod, IncomeAccount, PaymentMethodConfiguration } from 'xero-dental-deposit-common'
import { UntypedFormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../../data.service';
import { DepositConfiguration } from 'xero-dental-deposit-common';

@Component({
  selector: 'app-deposit-configuration-form',
  templateUrl: './deposit-configuration-form.component.html',
  styleUrls: ['./deposit-configuration-form.component.css']
})

export class DepositConfigurationFormComponent implements OnInit {
  public depositForm: UntypedFormGroup;
  private availablePaymentMethodConfigurations: PaymentMethodConfiguration[];
  private contacts: Contact[];
  private bankAccounts: BankAccount[];
  private incomeAccounts: IncomeAccount[];
  private paymentMethods: PaymentMethod[];
  private useFirstPaymentDescriptionAsMemo: boolean;
  public loading: boolean = true;
  private submitted: boolean;

  @Input() deposit: DepositConfiguration;
  @Output() save: EventEmitter<DepositConfiguration> = new EventEmitter();
  @Output() cancel: EventEmitter<void> = new EventEmitter();

  public bankAccountCompare(a: BankAccount, b: BankAccount): boolean {
    return a && b ? a.id === b.id : a === b;
  }

  public contactCompare(a: Contact, b: Contact): boolean {
    return a && b ? a.id === b.id : a === b;
  }

  onCancel() {
    this.cancel.emit();
  }

  addPaymentMethodConfiguration(paymentMethodConfig: PaymentMethodConfiguration) {
    (<UntypedFormArray>this.depositForm.controls.paymentMethodConfigurations)
      .push(this.fb.group({ paymentMethod: paymentMethodConfig.paymentMethod, maxItems: paymentMethodConfig.maxItems }));
    this.availablePaymentMethodConfigurations = this.availablePaymentMethodConfigurations.filter(item => item !== paymentMethodConfig);
    this.depositForm.markAsDirty();
  }

  removePaymentMethodConfiguration(paymentMethodConfig: PaymentMethodConfiguration, idx: number) {
    this.availablePaymentMethodConfigurations.push(paymentMethodConfig);
    this.availablePaymentMethodConfigurations.sort(PaymentMethodConfiguration.sortFn);
    (<UntypedFormArray>this.depositForm.controls.paymentMethodConfigurations).removeAt(idx);
    this.depositForm.markAsDirty();
  }

  onSubmit() {
    this.submitted = true;

    if (this.depositForm.invalid) {
      return;
    }

    const deposit = new DepositConfiguration();
    const formModel = this.depositForm.value;
    deposit.id = formModel.id;
    deposit.memo = formModel.memo;
    deposit.contact = formModel.contact;
    deposit.depositToAccount = formModel.depositToAccount;
    deposit.useFirstPaymentDescriptionAsMemo = formModel.useFirstPaymentDescriptionAsMemo;
    deposit.paymentMethodConfigurations = formModel.paymentMethodConfigurations
      .map((config) => PaymentMethodConfiguration.fromJSON(config));
    this.save.emit(deposit);
  }

  constructor(private fb: UntypedFormBuilder, private router: Router, private data: DataService, private route: ActivatedRoute) {
  }

  paymentMethodValidator(control: UntypedFormArray) {
    return control.length > 0 ? null : { 'mustHaveOneConfiguredPaymentMethod': true };
  }

  ngOnInit() {
    this.data.clientConfiguration.subscribe((clientConfiguration) => {
      console.log('got configuration...');
      this.contacts = clientConfiguration.contacts;
      this.paymentMethods = clientConfiguration.paymentMethods;
      this.bankAccounts = clientConfiguration.bankAccounts;
      this.incomeAccounts = clientConfiguration.incomeAccounts;
      this.availablePaymentMethodConfigurations = clientConfiguration.getAvailablePaymentMethodConfigurations();

      this.depositForm = this.fb.group({
        'id': [this.deposit.id],
        'memo': [this.deposit.memo, Validators.required],
        'contact': [this.deposit.contact || clientConfiguration.contacts[0]
          , Validators.required],
        'useFirstPaymentDescriptionAsMemo': [this.deposit.useFirstPaymentDescriptionAsMemo],
        'depositToAccount': [this.deposit.depositToAccount || clientConfiguration.bankAccounts[0], Validators.required],
        'paymentMethodConfigurations': this.fb.array(
          this.deposit.paymentMethodConfigurations
            .map(
              (depositItemConfiguration) => this.fb.group({
                paymentMethod: depositItemConfiguration.paymentMethod,
                maxItems: depositItemConfiguration.maxItems
              }))
          , this.paymentMethodValidator)
      }, {
        'validators': [
        ]
      });

      this.loading = false;
    });
  }
}
