import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../components/auth-service/auth.service';

@Component({
  selector: 'app-oauth-callback-component',
  templateUrl: './oauth-callback-component.component.html',
  styleUrls: ['./oauth-callback-component.component.css']
})
export class OAuthCallbackComponentComponent implements OnInit {
  private code: string
  public tenants: any

  constructor(private route: ActivatedRoute, private http: HttpClient, private authService: AuthService) { }

  ngOnInit(): void {
    this.http.get<any>(`/api/xeroExchangeAuthToken${window.location.search}`).subscribe((x) => {
      this.tenants = x.tenants
    })
  }
}
