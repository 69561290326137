import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';
import { AuthService, Tenant } from '../auth-service/auth.service';

@Component({
  selector: 'app-token',
  templateUrl: './token.component.html',
  styleUrls: ['./token.component.css']
})
export class TokenComponent implements OnInit {

  public token: string;
  public tenant: Tenant;
  constructor(private auth: AuthService, private http: HttpClient) { }

  ngOnInit(): void {
    this.auth.selectedTenant.pipe(filter((tenant) => tenant != null)).subscribe((tenant) => {
      this.tenant = tenant
      this.http.get<any>(`/api/${tenant.tenantId}/clienttoken`).subscribe((data) => {
        this.token = data.token
      }
      );
    })
  }
}