<form [formGroup]="payTypeMappingForm">
  <div class="row">
    <div class="col-lg">
      <p>In this screen you will setup the mapping between the practice management payment types and the payment types
        used in the accounting software. This will also include the configuration on which income account is used for
        each payment method.</p>
      <p>For example, in the PMS software you might have Visa, MC, American Express, but in the accounting software you just have credit cards. This is where this mapping takes plan</p>
      <p>Once these are configured, you will use the <a [routerLink]="['/setup/deposits']">Setup Deposits</a> screen to
        configure one or more deposits to include one or more Payment Methods.</p>
    </div>
  </div>
  <div class="row">
    <div class="col-lg">
      <table class="table table-striped table-bordered" formArrayName="payTypeMappings">
        <thead>
          <th>Payment Type</th>
          <th>Payment Method</th>
          <th>Income Account</th>
        </thead>
        <tr *ngFor="let payTypeMappingFormGroup of getPayTypeMappingsFormGroup(); let i = index"
          [formGroupName]="i">
          <td>
            {{ payTypeMappingFormGroup.controls.paymentType.value.name }}
          </td>
          <td>
            <select (change)="change(i)" formControlName="paymentMethod" id="paymentMethod" [compareWith]="compareFn">
              <option [ngValue]="paymentMethod" *ngFor="let paymentMethod of paymentMethods">{{ paymentMethod.name }}</option>
            </select>
          </td>

          <td>
            <select (change)="change(i)" formControlName="incomeAccount" id="incomeAccount" [compareWith]="compareFn">
              <option [ngValue]="incomeAccount" *ngFor="let incomeAccount of incomeAccounts">{{ incomeAccount.name }}</option>
            </select>
          </td>
        </tr>
      </table>
    </div>
  </div>
</form>