export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyA8-aAzcupo8pw6rgptS1QhznonHLsnlCs',
    authDomain: 'dentaldeposit-prod.firebaseapp.com',
    databaseURL: 'https://dentaldeposit-prod.firebaseio.com',
    projectId: 'dentaldeposit-prod',
    storageBucket: 'dentaldeposit-prod.appspot.com',
    messagingSenderId: '282149219448',
  }
};
