
  <div class="row">
      <div class="col-lg">
        <p>In this screen you will setup the deposits which will be entered into your accounting software. A deposit is made up of the following information</p>

        <ol>
          <li>The name of the deposit which will appear in the memo line.</li>
          <li>The payment methods which will be included on this deposit. (ex: cash/check). Additionaly, you can configure a limit to the number of transactions included on a deposit</li>
          <li>The bank account where the deposit will be made</li>
          <li>The contact from where the deposit will be from (Ex: Patient)</li>
        </ol>
                  
      </div>
    </div>

    <div class="row">
      <div class="col-lg">
          <table *ngIf="clientConfiguration | async; let clientConfiguration; else loading" class="table-bordered table table-striped">
              <thead>
                <th>Memo</th>
                <th>Payment Methods</th>
                <th>Bank Account</th>
                <th>Contact</th>
                <th>Actions</th>
              </thead>
              <tr *ngFor="let deposit of clientConfiguration.depositConfigurations.depositConfigurations">
                <td><a [routerLink]="['/setup/deposit',deposit.id]">{{ deposit.memo }}</a></td>
                <td>{{ deposit.getDescription() }}</td>
                <td>{{ deposit.depositToAccount.name }}</td>
                <td>{{ deposit.contact.name }}</td>
                <td>
                  <a [routerLink]="['/setup/deposit', deposit.id]">Edit</a> | 
                  <a [routerLink]="" (click)="deleteDeposit(deposit)">Delete</a>
                </td>
              </tr>
            </table>
            <span ngbTooltip="All the available payment methods have been configured. Additional deposits cannot be added." [disableTooltip]="hasUnconfiguredPaymentTypes">
              <button [disabled]="!hasUnconfiguredPaymentTypes" (click)="newDeposit()" class="btn btn-success">New Deposit</button>
            </span>
            <ng-template #loading>
              <h2>Loading...</h2>
            </ng-template>
      </div>
    </div>
