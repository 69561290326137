import { PaymentType, IncomeAccount, AdjustmentType, Contact, PayTypeMapping, BankAccount, PaymentMethod } from './BasicTypes.js';
import { RefundMappingConfiguration } from "./RefundMappingConfiguration.js";
import { DepositConfiguration } from './DepositConfiguration.js';
import { DepositConfigurations } from './DepositConfigurations.js';
import { PaymentMethodConfiguration } from './PaymentMethodConfiguration.js';
export class ClientConfiguration {
    constructor() {
        this.paymentTypes = [];
        this.paymentMethods = [];
        this.bankAccounts = [];
        this.incomeAccounts = [];
        this.payTypeMappings = [];
        this.adjustmentTypes = [];
        this.contacts = [];
        /** A mapping of AdjustmentType to RefundMapping Configuration. */
        this.refundMappings = [];
        this.depositConfigurations = new DepositConfigurations();
    }
    hasUnconfiguredPaymentMethods() {
        return this.getUnconfiguredPaymentMethods().length > 0;
    }
    getUnconfiguredPaymentMethods() {
        const usedPaymentMethods = this.depositConfigurations.getUsedPaymentMethods();
        const availablePaymentMethods = this.paymentMethods.filter(paymentMethod => usedPaymentMethods
            .find(usedPaymentMethod => usedPaymentMethod.id === paymentMethod.id) === undefined);
        return availablePaymentMethods;
    }
    getAvailablePaymentMethodConfigurations() {
        const availablePaymentMethods = this.getUnconfiguredPaymentMethods();
        return availablePaymentMethods.map(availablePaymentMethod => {
            const depositItemConfiguration = new PaymentMethodConfiguration();
            depositItemConfiguration.paymentMethod = availablePaymentMethod;
            depositItemConfiguration.maxItems = 0;
            return depositItemConfiguration;
        });
    }
    getRefundMappingByAdjustmentTypeId(id) {
        const refundMapping = this.refundMappings.find((refundMapping) => refundMapping.adjustmentType.id === id);
        return refundMapping;
    }
    getContactByID(id) {
        return this.contacts.find((contact) => contact.id === id);
    }
    getPayTypeMapping(id) {
        return this.payTypeMappings.find((item) => item.paymentType.id === id);
    }
    addPayTypeMapping(mapping) {
        if (mapping.paymentType === undefined) {
            throw new Error("paytype mapping cannot be undefined");
        }
        this.payTypeMappings.push(mapping);
    }
    getPaymentTypeByName(name) {
        let results = this.paymentTypes.find((payType) => payType.name.toLowerCase() === name.toLowerCase());
        if (results === undefined) {
            throw new Error("Unable to locate Payment Type with name " + name);
        }
        return results;
    }
    getPaymentType(id) {
        let results = this.paymentTypes.find((payType) => payType.id === id);
        if (results === undefined) {
            throw new Error("Unable to locate Payment Type with name " + name);
        }
        return results;
    }
    getPaymentMethod(id) {
        let results = this.paymentMethods.find((paymentMethod) => paymentMethod.id === id);
        if (results === undefined) {
            throw new Error(`Unable to locate PaymentMethod Type with id "${id}"`);
        }
        return results;
    }
    getPaymentMethodByName(name) {
        let results = this.paymentMethods.find((paymentMethod) => paymentMethod.name.toLowerCase() === name.toLowerCase());
        if (results === undefined) {
            throw new Error(`Unable to locate PaymentMethod Type with name "${name}"`);
        }
        return results;
    }
    getDepositConfigurationById(id) {
        for (const deposit of this.depositConfigurations.depositConfigurations) {
            if (deposit.id === id) {
                return deposit;
            }
        }
        return undefined;
    }
    getDepositConfigurationByPaymentMethodName(paymentMethodName) {
        const paymentMethod = this.getPaymentMethodByName(paymentMethodName);
        for (const deposit of this.depositConfigurations.depositConfigurations) {
            if (deposit.containsPaymentMethod(paymentMethod)) {
                return deposit;
            }
        }
        return undefined;
    }
    getDepositConfigurationByPaymentMethod(paymentMethod) {
        for (const deposit of this.depositConfigurations.depositConfigurations) {
            if (deposit.containsPaymentMethod(paymentMethod)) {
                return deposit;
            }
        }
        return undefined;
    }
    getBankAccountByName(name) {
        let results = this.bankAccounts.find((bankAccount) => bankAccount.name === name);
        if (results === undefined) {
            throw new Error(`Unable to locate Bank Account with name "${name}"`);
        }
        return results;
    }
    getBankAccount(id) {
        let results = this.bankAccounts.find((bankAccount) => bankAccount.id === id);
        if (results === undefined) {
            throw new Error("Unable to locate Bank Account with id " + id);
        }
        return results;
    }
    getIncomeAccountByName(name) {
        let results = this.incomeAccounts.find((incomeAccount) => incomeAccount.name === name);
        if (results === undefined) {
            throw new Error(`Unable to locate Income Account with name ${name}`);
        }
        return results;
    }
    getAdjustmentType(id) {
        let results = this.adjustmentTypes.find((adjustmentType) => adjustmentType.id === id);
        if (results === undefined) {
            throw new Error(`Unable to locate Adjustment Type with id ${id}`);
        }
        return results;
    }
    getIncomeAccount(id) {
        let results = this.incomeAccounts.find((incomeAccount) => incomeAccount.id === id);
        if (results === undefined) {
            throw new Error(`Unable to locate Income Account with id ${id}`);
        }
        return results;
    }
    static fromJSON(configJson) {
        const clientConfiguration = new ClientConfiguration();
        configJson.bankAccounts.forEach((json) => { clientConfiguration.bankAccounts.push(BankAccount.fromJSON(json)); });
        configJson.incomeAccounts.forEach((json) => { clientConfiguration.incomeAccounts.push(IncomeAccount.fromJSON(json)); });
        configJson.contacts.forEach((json) => { clientConfiguration.contacts.push(Contact.fromJSON(json)); });
        configJson.paymentMethods.forEach((json) => { clientConfiguration.paymentMethods.push(PaymentMethod.fromJSON(json)); });
        configJson.paymentTypes.forEach((json) => { clientConfiguration.paymentTypes.push(PaymentType.fromJSON(json)); });
        configJson.adjustmentTypes.forEach((json) => { clientConfiguration.adjustmentTypes.push(AdjustmentType.fromJSON(json)); });
        if (configJson.depositConfigurations) {
            Object.keys(configJson.depositConfigurations).forEach((key) => {
                const json = configJson.depositConfigurations[key];
                clientConfiguration.depositConfigurations.addDepositConfiguration(DepositConfiguration.fromJSON(json));
            });
        }
        if (configJson.payTypeMappings) {
            configJson.payTypeMappings.forEach((json) => {
                clientConfiguration.payTypeMappings.push(PayTypeMapping.fromJSON(json));
            });
        }
        if (configJson.refundMappings) {
            Object.keys(configJson.refundMappings).forEach((key) => {
                const json = configJson.refundMappings[key];
                clientConfiguration.refundMappings.push(RefundMappingConfiguration.fromJSON(json));
            });
        }
        // Here we fix up the payment mappings so that every paymentType is represented.
        for (const paymentType of clientConfiguration.paymentTypes) {
            if (clientConfiguration.getPayTypeMapping(paymentType.id) === undefined) {
                clientConfiguration.addPayTypeMapping(new PayTypeMapping({ paymentType: paymentType, incomeAccount: null, paymentMethod: null }));
            }
        }
        return clientConfiguration;
    }
}
