import { CheckToWrite } from './CheckToWrite.js';
import { Deposit } from './Deposit.js';
import { DepositLine } from './BasicTypes.js';
export class DailyDeposit {
    constructor(config) {
        this.config = config;
        this.depositMap = new Map();
        this.checksToWrite = [];
        this.total = 0;
    }
    getDeposits() {
        return [].concat(...this.depositMap.values());
    }
    processDeposits(callBackFn) {
        let promises = [];
        let depositsConfigurations = [...this.depositMap.keys()];
        depositsConfigurations.sort((a, b) => (a.order > b.order) ? 1 : (a.order < b.order ? -1 : 0));
        for (let depositConfiguration of depositsConfigurations) {
            const deposits = this.depositMap.get(depositConfiguration);
            for (let deposit of deposits) {
                promises.push(callBackFn(depositConfiguration, deposit));
            }
        }
        return Promise.all(promises);
    }
    processRefundChecks(callBackFn) {
        let promises = [];
        for (let check of this.checksToWrite) {
            promises.push(callBackFn(check));
        }
        return Promise.all(promises);
    }
    needsDepositSlips() {
        let needsDepositSlip = false;
        for (const depositArray of this.depositMap.values()) {
            for (const deposit of depositArray) {
                if (deposit.needsDepositSlip()) {
                    return true;
                }
            }
        }
        return false;
    }
    addCheck(check) {
        this.checksToWrite.push(check);
    }
    addRefund(refund) {
        const adjustmentType = this.config.getAdjustmentType(refund.adjustmentId);
        const refundMapping = this.config.getRefundMappingByAdjustmentTypeId(adjustmentType.id);
        if (refundMapping === undefined) {
            throw new Error(`no refund mapping found for refund type '${refund.description}'`);
        }
        const paytypeMapping = this.config.getPayTypeMapping(refundMapping.paymentType.id);
        const depositConfiguration = this.config.depositConfigurations.getDepositConfiguration(paytypeMapping.paymentMethod);
        if (depositConfiguration === undefined) {
            throw new Error(`paytype '${refundMapping.paymentType.name}' belongs to no deposit configuration`);
        }
        const deposit = this.getDeposit(depositConfiguration, refundMapping.paymentType);
        deposit.addLine(new DepositLine({
            amount: -refund.amount,
            memo: refund.description,
            paymentType: paytypeMapping.paymentMethod,
            paymentMethod: paytypeMapping.paymentMethod,
            incomeAccount: paytypeMapping.incomeAccount
        }));
    }
    addPayment(p) {
        const paymentType = this.config.getPaymentType(p.paymentTypeId);
        const mapping = this.config.getPayTypeMapping(paymentType.id);
        if (mapping.paymentMethod === null) {
            throw new Error(`payment type mapping for ${paymentType.name} does not map to any payment method.`);
        }
        const depositConfiguration = this.config.getDepositConfigurationByPaymentMethod(mapping.paymentMethod);
        if (depositConfiguration === undefined) {
            throw new Error(`payment method '${mapping.paymentMethod.name}' does not belong to any deposit configuration.`);
        }
        this.total += p.amount;
        const deposit = this.getDeposit(depositConfiguration, paymentType);
        const depositline = new DepositLine({
            amount: p.amount,
            paymentMethod: mapping.paymentMethod,
            paymentType: paymentType,
            checkNumber: p.checkNumber,
            memo: p.description,
            incomeAccount: mapping.incomeAccount
        });
        deposit.addLine(depositline);
    }
    isDepositFull(deposit, paymentType) {
        let mapping = this.config.getPayTypeMapping(paymentType.id);
        const config = this.config.depositConfigurations.getDepositConfiguration(mapping.paymentMethod);
        return config.getPaymentMethodMax(mapping.paymentMethod) > 0 && deposit.getCount(mapping.paymentMethod) === config.getPaymentMethodMax(mapping.paymentMethod);
    }
    static fromJSON(clientConfiguration, depositObject) {
        const deposit = new DailyDeposit(clientConfiguration);
        deposit.date = depositObject.date;
        deposit.id = depositObject.id;
        deposit.dateProcessed = depositObject.dateProcessed;
        if (depositObject.payments) {
            depositObject.payments.forEach((payment) => {
                deposit.addPayment(payment);
            });
        }
        if (depositObject.refunds) {
            depositObject.refunds.forEach((refund) => {
                deposit.addRefund(refund);
            });
        }
        if (depositObject.checksToWrite) {
            depositObject.checksToWrite.forEach((checkToWriteJSONObj) => {
                const checkToWrite = CheckToWrite.fromJSON(checkToWriteJSONObj);
                const mapping = clientConfiguration.getRefundMappingByAdjustmentTypeId(checkToWriteJSONObj.adjustmentType.id);
                checkToWrite.bankAccount = mapping.bankAccount;
                checkToWrite.incomeAccount = mapping.incomeAccount;
                deposit.addCheck(checkToWrite);
            });
        }
        return deposit;
    }
    getDeposit(config, paymentType) {
        if (this.depositMap.has(config)) {
            const bottomDeposit = this.depositMap.get(config)[this.depositMap.get(config).length - 1];
            const topDeposit = this.depositMap.get(config)[0];
            // Try to put in the first deposit first. TODO: this might not be exactly what we want. 
            if (this.isDepositFull(bottomDeposit, paymentType) == false) {
                return bottomDeposit;
            }
            if (this.isDepositFull(topDeposit, paymentType)) {
                const newDeposit = new Deposit({ depositToAccount: config.depositToAccount, contact: config.contact, memo: config.memo });
                this.depositMap.get(config).unshift(newDeposit);
                return newDeposit;
            }
            return topDeposit;
        }
        else {
            const newDeposit = new Deposit({ depositToAccount: config.depositToAccount, contact: config.contact, memo: config.memo });
            this.depositMap.set(config, [newDeposit]);
            return newDeposit;
        }
    }
}
