import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { HttpClient } from '@angular/common/http';
import { DailyDeposit } from 'xero-dental-deposit-common';

@Component({
  selector: 'app-deposits',
  templateUrl: './deposits.component.html',
  styleUrls: ['./deposits.component.css']
})
export class DepositsComponent implements OnInit {
  public date: string;
  public recentDeposits: DailyDeposit[];

  constructor(private httpClient: HttpClient,
    private dataservice: DataService) {
  }

  ngOnInit() {
    this.dataservice.recentDeposits
    this.dataservice.recentDeposits.subscribe((x) => this.recentDeposits = x);
  }
  
  public completedDeposits(): DailyDeposit[] {
    const completed = this.recentDeposits.filter((dailyDeposit) => dailyDeposit.dateProcessed);
    return completed;
  }

  public pendingDeposits(): DailyDeposit[] {
    const pending =  this.recentDeposits.filter((dailyDeposit) => !dailyDeposit.dateProcessed);
    return pending;
  }
}
