import { Component, OnInit } from '@angular/core';
import { PaymentType, PaymentMethod, IncomeAccount } from 'xero-dental-deposit-common';
import { DataService } from '../../../data.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { Account } from 'xero-dental-deposit-common';

@Component({
  selector: 'app-paytype-mapping',
  templateUrl: './paytype-mapping.component.html',
  styleUrls: ['./paytype-mapping.component.css']
})

export class PaytypeMappingComponent implements OnInit {
  payTypeMappingForm: UntypedFormGroup;
  paymentMethods: PaymentMethod[];
  incomeAccounts: IncomeAccount[];
  paymentTypes: PaymentType[];
  _fb: UntypedFormBuilder;

  public compareFn(a: Account, b: Account) {
    return a && b ? a.id === b.id : a === b;
  }

  change(idx: number) {
    this.data.setPayTypeMapping(this.payTypeMappingForm.value.payTypeMappings[idx]);
  }

  public constructor(public data: DataService) {
    this._fb = new UntypedFormBuilder();
  }

  getPayTypeMappingsFormGroup()  {
    return (<UntypedFormGroup>this.payTypeMappingForm.controls.payTypeMappings).controls;
  }

  ngOnInit() {
    this.payTypeMappingForm = this._fb.group({ 'payTypeMappings': this._fb.array([]) });

    this.data.clientConfiguration.pipe(filter((x) => x !== undefined))
    .subscribe((clientConfiguration) => {
      this.paymentTypes = clientConfiguration.paymentTypes;
      this.paymentMethods = clientConfiguration.paymentMethods;
      this.incomeAccounts = clientConfiguration.incomeAccounts;
      const payTypeMappings = clientConfiguration.payTypeMappings || [];

      const groups = payTypeMappings.map(payTypeMapping => this._fb.group(
        {
          'paymentType': payTypeMapping.paymentType || null,
          'paymentMethod': payTypeMapping.paymentMethod || null,
          'incomeAccount': payTypeMapping.incomeAccount || null
        }));

      const array = this._fb.array(groups);
      this.payTypeMappingForm.setControl('payTypeMappings', array);
      });
  }

}
