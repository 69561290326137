import { Injectable, OnInit } from '@angular/core';
import {
  DepositConfiguration,
  ClientConfiguration,
  DailyDeposit,
  PayTypeMapping,
  PaymentFile,
  RefundMappingConfiguration
} from 'xero-dental-deposit-common';

import { AuthService } from './components/auth-service/auth.service';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Database, ref, objectVal } from '@angular/fire/database'
import { DatabaseReference, set, push, remove} from '@angular/fire/database';

@Injectable()
export class DataService implements CanActivate {
  private configurationSubscription: Subscription;
  private depositsSubscription: Subscription;
  public clientConfiguration: BehaviorSubject<ClientConfiguration>;
  public recentDeposits: BehaviorSubject<DailyDeposit[]>;
  private databaseURL: string;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    console.log("can activate?")
    return this.clientConfiguration.pipe(filter((x) => x !== undefined))
      .pipe(take(1))
      .pipe(tap((x) => { console.log(`can activate in data service ${!!x}`); }))
      .pipe(map((x) => !!x));
  }

  public constructor(public auth: AuthService, private db: Database, private httpClient: HttpClient) {
    this.init();

  }

  public init() {
    this.recentDeposits = new BehaviorSubject([]);
    this.clientConfiguration = new BehaviorSubject<ClientConfiguration>(undefined);

    this.auth.selectedTenant.pipe(filter((x) => x !== null)).subscribe((tenant) => {

      console.log(`data.service: obtained tenant of ${tenant.tenantName} loading configuration...`);
      this.databaseURL = `/xero/${tenant.tenantId}`;

      if (this.configurationSubscription !== undefined) {
        console.log(`canceling old configuration subscription.`);
        this.configurationSubscription.unsubscribe();
      }

      this.configurationSubscription =  objectVal(ref(this.db, `${this.databaseURL}/configuration`))
        .subscribe((configuration) => {
          console.log(`data.service: obtained configuration.`);
          const configurationObject = ClientConfiguration.fromJSON(configuration);
          this.clientConfiguration.next(configurationObject);
        });

      this.depositsSubscription = this.clientConfiguration
        .pipe(filter((x) => x !== undefined))
        .subscribe((clientConfiguration) => {
          console.log(`data.service: obtained deposits for ${tenant.tenantName}`);

          if (this.depositsSubscription !== undefined) {
            this.depositsSubscription.unsubscribe();
          }
          
          this.depositsSubscription = objectVal(ref(this.db,`${this.databaseURL}/deposit`))
            .subscribe((deposits) => {
              console.log(`data.service: obtained deposits for ${tenant.tenantName}`);

              if (deposits === null) {
                deposits = {};
              }

              const depositObjects: DailyDeposit[] = [];
              for (const id of Object.keys(deposits)) {
                try {
                  const deposit = deposits[id];
                  console.log(deposit)
                  const dailyDeposit: DailyDeposit = DailyDeposit.fromJSON(clientConfiguration, deposit);
                  depositObjects.push(dailyDeposit);
                } catch (error) {
                  console.log(error)
                }
              }

              this.recentDeposits.next(depositObjects);
            });
        });
    });
  }

  public getDepositConfiguration(id: string): Observable<DepositConfiguration> {
    return objectVal(ref(this.db, `${this.databaseURL}/configuration/depositConfigurations/${id}`))
      .pipe(map((x) => { console.log(x); return DepositConfiguration.fromJSON(x); }));
  }

  public async setPayTypeMapping(payTypeMapping: PayTypeMapping) : Promise<void> {
    const paymentTypeRef : DatabaseReference = ref(this.db,`${this.databaseURL}/configuration/payTypeMappings/${payTypeMapping.paymentType.id}`)
    return set(paymentTypeRef, payTypeMapping)
  }

  public async setDepositConfiguration(deposit: DepositConfiguration) : Promise<void> {
    const configurationsRef = ref(this.db, `${this.databaseURL}/configuration/depositConfigurations`)

    if (!deposit.id) {
      const newElement = await push(configurationsRef)
      deposit.id = newElement.key
    }
    
    const objectRef = ref(this.db,`${this.databaseURL}/configuration/depositConfigurations/${deposit.id}`)
    return set(objectRef, deposit)
  }

  public setRefundMapping(refundMapping: RefundMappingConfiguration) {
    const paymentTypeRef : DatabaseReference = ref(this.db,`${this.databaseURL}/configuration/refundMappings/${refundMapping.adjustmentType.id}`)
    return set(paymentTypeRef, refundMapping)
  }

  public deleteRefundMapping(refundMapping: RefundMappingConfiguration) : Promise<void> {
    const refundMappingRef = ref(this.db, `${this.databaseURL}/configuration/refundMappings/${refundMapping.adjustmentType.id}`)
    return remove(refundMappingRef)
  }

  public deleteDepositConfiguration(deposit: DepositConfiguration) {
    const depositRef = ref(this.db, `${this.databaseURL}/configuration/depositConfigurations/${deposit.id}`)
    return remove(depositRef)
  }

  public async markDepositAsProcessed(dailyDeposit: DailyDeposit) : Promise<void> {
    const depositRef = ref(this.db,`${this.databaseURL}/deposit/${dailyDeposit.id}/dateProcessed`)
    return set(depositRef, new Date().toISOString())
  }

  public deleteDeposit(dailyDeposit: DailyDeposit) : Promise<void> {
    const depositRef = ref(this.db,`${this.databaseURL}/deposit/${dailyDeposit.id}`)
    return remove(depositRef)
  }

  public async unmarkDepositAsProcessed(dailyDeposit: DailyDeposit) : Promise<void> {
    const depositRef = ref(this.db,`${this.databaseURL}/deposit/${dailyDeposit.id}/dateProcessed`)
    return remove(depositRef)
  }

  public obtainPaymentFileById(id: string): Observable<PaymentFile> {
    const depositRef = ref(this.db, `${this.databaseURL}/deposit/${id}`)
    return objectVal<PaymentFile>(depositRef)
  }

  public setPaymentType(id: string, idx: number, newPayTypeId: number) : Promise<void> {
    const paymentTypeRef = ref(this.db, `${this.databaseURL}/deposit/${id}/payments/${idx}/paymentTypeId`)
    return set(paymentTypeRef, newPayTypeId)
  }

  public obtainDepositById(id: string): Observable<DailyDeposit> {
    return new Observable((observer) => {
      this.clientConfiguration.subscribe((clientConfiguration) => {
        this.obtainPaymentFileById(id).subscribe((paymentFile: PaymentFile) => {
          try {
            const dailyDeposit: DailyDeposit = new DailyDeposit(clientConfiguration);

            dailyDeposit.id = id;
            dailyDeposit.date = paymentFile.date;

            if (paymentFile.refunds) {
              for (const refund of paymentFile.refunds) {
                dailyDeposit.addRefund(refund);
              }
            }

            if (paymentFile.payments) {
              for (const payment of paymentFile.payments) {
                dailyDeposit.addPayment(payment);
              }
            }

            if (paymentFile.checksToWrite) {
              for (const checkToWrite of paymentFile.checksToWrite) {
                dailyDeposit.addCheck(checkToWrite);
              }
            }
            observer.next(dailyDeposit);
          } catch (error) {
            console.log(error)
          }
        }
        );
      });
    });
  }
}
