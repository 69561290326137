import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Observable, BehaviorSubject, Subscription, of, combineLatest } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { GoogleAuthProvider } from "@angular/fire/auth"

import { take, map, tap, switchMap, filter } from 'rxjs/operators';
import { ref, objectVal, Database } from '@angular/fire/database';
import { Auth, signInWithPopup, user, User } from '@angular/fire/auth'

export interface Tenant {
  tenantId: string
  tenantName: string
  shortCode: string
}

@Injectable()
export class AuthService {
  public isLoggedIn: boolean;
  public emailAddress: string;
  public authenticatedUser: Observable<User>;
  public selectedTenant: BehaviorSubject<Tenant>;
  public availableTenants: Observable<Tenant[]>;
  public subscriptions: Subscription[];

  constructor(private firebaseAuth: Auth,
    private db: Database,
    private router: Router,
    private cookieService: CookieService) {

    this.authenticatedUser = user(firebaseAuth)
    this.selectedTenant = new BehaviorSubject(null);

    this.availableTenants = this.authenticatedUser
      .pipe(filter((authenticatedUser) => authenticatedUser !== undefined && authenticatedUser != null))
      .pipe(switchMap((authenticatedUser) => objectVal(ref(db,`/user/${authenticatedUser.uid}/tenants`)).pipe(take(1))))
      .pipe(map((tenantIds: string[]) => { 
        const requests : Observable<Tenant>[] = []
          for (const idx in tenantIds) { 
            const tenantId = tenantIds[idx];
            requests.push(objectVal<Tenant>(ref(db, `/xero/${tenantId}`)))
          }
          return requests;
        })).pipe(switchMap(_ => combineLatest(_)))


    this.selectedTenant.pipe(filter((x) => (x !== null))).subscribe((tenant) => {
      console.log(`selected tenant is now ${tenant.tenantName}`);
      console.log(tenant.tenantId)
      console.log(cookieService)
      cookieService.set('__tenantId', tenant.tenantId, undefined, '/');
    });

    this.availableTenants.subscribe((tenants) => {
      const currentTenantId = cookieService.get('__tenantId');
      const selectedTenant =
        (currentTenantId !== undefined && tenants.find((tenant) => tenant.tenantId === currentTenantId)) || tenants[0] || null
      this.selectedTenant.next(selectedTenant);
    });

    this.authenticatedUser.subscribe(currentUser => {
      if (currentUser === null) {
        console.log(`no user is logged in.`);
        this.isLoggedIn = false;
        this.router.navigate(['/home']);
      } else {
        console.log(`user ${currentUser.uid} has logged in. `);
        this.isLoggedIn = true;
        this.emailAddress = currentUser ? currentUser.email : null;
        if (currentUser === null) {
          this.cookieService.delete('__session');
        } else {
          currentUser.getIdToken().then(token =>
            cookieService.set('__session', token)
          );
        }
      }
    });
  }

  switchTenant(tenant: Tenant) {
    console.log(`switching user to ${tenant.tenantName}`);
    this.selectedTenant.next(tenant);
    this.router.navigate(['/home']);
  }

  login() {
    signInWithPopup(this.firebaseAuth, new GoogleAuthProvider()).then((x) => {
      console.log(x);
    });
  }

  refreshCookie() {
    this.authenticatedUser.pipe(take(1)).subscribe((currentUser) => {
        if (currentUser != null) {
          console.log(`refreshingCookie for ${currentUser.uid}.`);
          currentUser.getIdToken().then(token => {
              console.log(`token is ${token}`);
              this.cookieService.set('__session', token);
            }
          );
        }
      });
  }

  logout() {
    this.firebaseAuth.signOut()
    this.router.navigate(['/home']);
  }
}

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private auth: AuthService, private router: Router) {
  }

  canActivate(): Observable<boolean> {
    const isLoggedInObserver = this.auth.authenticatedUser
      .pipe(take(1))
      .pipe(tap((user) => { console.log(`got username of ${user.uid}`); }))
      .pipe(map((user) => !!user));

    const isTenantSelected = this.auth.selectedTenant
      .pipe(take(1))
      .pipe(tap((selectedTenant) => { if (selectedTenant) { console.log(`got selectedTenant of ${selectedTenant.tenantName}`) } else { console.log(`no tenant selected.`)} }))
      .pipe(map((selectedTenant) => selectedTenant !== null))

      isTenantSelected.subscribe((x) => console.log)

      return of(true)
    // return forkJoin([isLoggedInObserver, isTenantSelected])
    //   .pipe(tap(([isLoggedIn, isTenantSelected]) => { console.log(`isLoggedIn=${isLoggedIn}, hasUserMapEntry=${isTenantSelected}`); }))
    //   .pipe(map(([isLoggedIn, isTenantSelected]) => isLoggedIn && isTenantSelected))
    //   .pipe(tap((shouldBeAllowed) => {
    //     if (!shouldBeAllowed) {
    //       console.log('not logged in, redirecting to home.');
    //       this.router.navigate(['/home']);
    //     }
    //   }));
  }
}
