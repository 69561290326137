<form *ngIf="refundMappingForm" [formGroup]="refundMappingForm" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-lg">
      <p>...</p>
      <p>Once these are configured, you will use the <a [routerLink]="['/setup/deposits']">Setup Deposits</a> screen to
        configure one or more deposits to include one or more Payment Methods.</p>
    </div>
  </div>
  <div class="row">
    <div class="col-lg">
      <select formControlName="adjustmentType" size="10" [compareWith]="compareFn">
        <option [ngValue]="adjustmentType" *ngFor="let adjustmentType of adjustmentTypes"  >{{ adjustmentType.name }}</option>
      </select>
    </div>

    <div *ngIf="refundMappingForm.controls.refundMapping" class="col-lg" formGroupName="refundMapping">

      <div class="form-group">
        <label for="refundType">Refund Type</label>
        <select class="form-control" formControlName="refundType" id="refundType">
          <option value="none">Disabled</option>
          <option value="refundCheck">Refund Check</option>
          <option value="includeWithDeposit">Adjust in Deposit</option>
        </select>
        <small id="refundTypeHelp" class="form-text text-muted">Refund method for this adjustment type.</small>
      </div>

      <div *ngIf="refundMappingForm.get('refundMapping').get('refundType').value != 'none'">

        <div class="form-group">
          <label for="incomeAccount">Income Account</label>
          <select class="form-control" formControlName="incomeAccount" id="incomeAccount" [compareWith]="compareFn">
            <option [ngValue]="incomeAccount" *ngFor="let incomeAccount of incomeAccounts">{{ incomeAccount.name }}</option>
          </select>
          <small id="emailHelp" class="form-text text-muted">The income account where this refund will be issued.</small>
        </div>

        <div class="form-group" *ngIf="refundMappingForm.get('refundMapping').get('refundType').value == 'refundCheck'">
          <label for="bankAccount">Bank Account</label>
          <select class="form-control" formControlName="bankAccount" id="bankAccount" [compareWith]="compareFn">
            <option [ngValue]="bankAccount" *ngFor="let bankAccount of bankAccounts">{{ bankAccount.name }}</option>
          </select>
          <small id="bankAccountHelp" class="form-text text-muted">The bank account where this refund check will be
            issued.</small>
        </div>

        <div class="form-group" *ngIf="refundMappingForm.get('refundMapping').get('refundType').value == 'refundCheck'">
          <label for="checkGoesTo">Check Goes To</label>
          <select class="form-control" formControlName="checkGoesTo" id="checkGoesTo">
            <option value="insuranceCompany">Insurance Company</option>
            <option value="responsibleParty">Responsible Party</option>
          </select>
          <small id="paymentTypeHelp" class="form-text text-muted">The bank account where this refund check will be
            issued.</small>
        </div>

        <div class="form-group" *ngIf="refundMappingForm.get('refundMapping').get('refundType').value === 'includeWithDeposit'">
          <label for="paymentType">Payment Type</label>
          <select class="form-control" formControlName="paymentType" id="paymentType" [compareWith]="compareFn">
            <option [ngValue]="paymentType" *ngFor="let paymentType of paymentTypes">{{ paymentType.name }}</option>
          </select>
          <small id="paymentTypeHelp" class="form-text text-muted">The bank account where this refund check will be
            issued.</small>
        </div>
      </div>
      <button type="submit" [disabled]="refundMappingForm.pristine" class="btn btn-success">Save</button>
    </div>
  </div>
</form>