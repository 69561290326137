import { Component, OnInit } from '@angular/core';
import { AuthService, Tenant } from '../auth-service/auth.service';
import { Router, RouterModule } from '@angular/router'
import { NgbDropdownMenu, NgbDropdownModule, NgbNavbar } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
@Component({
  standalone: true,
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
  imports: [ CommonModule, NgbDropdownModule, RouterModule ]
})
export class NavbarComponent implements OnInit {
  public availableTenants: Tenant[];
  public ngbNav : NgbNavbar

  public constructor(public authService: AuthService, public router: Router) {
    authService.availableTenants.subscribe((availableTenants: Tenant[]) => {
      this.availableTenants = availableTenants;
    });
    
  }
  ngOnInit(): void {
  }

}
