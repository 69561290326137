<p *ngIf="message">
    {{ message }}
</p>

<p *ngIf="success">
    Great! Now that we have imported your data from XERO, you need to configure the <a [routerLink]="['/setup/paytypes']">Payment Type Mapping</a> and <a [routerLink]="['/setup/deposits']">Deposits.</a>
</p>

<div>
    <button class="btn btn-success" [disabled]="!enabled" (click)="setupXero()">Connect to Xero</button>
</div>