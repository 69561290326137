import { HttpClient } from '@angular/common/http';
import { Component, CUSTOM_ELEMENTS_SCHEMA, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { DailyDeposit } from 'xero-dental-deposit-common';
import { AuthService } from '../components/auth-service/auth.service';
import { DataService } from '../data.service';

@Component({
  selector: 'app-deposit-action-component',
  templateUrl: './deposit-action-component.component.html',
  styleUrls: ['./deposit-action-component.component.css']
})
export class DepositActionComponentComponent implements OnInit {
  @Input("showMove")
  public showMove: boolean = true;
  @Input("showDelete")
  public showDelete: boolean = true;
  @Input("deposit")
  public deposit: DailyDeposit
  public processing: boolean = false;

  constructor(private authService: AuthService, private dataservice: DataService, private httpClient: HttpClient, private toastr: ToastrService ) { }

  ngOnInit(): void {
  }

  printDepositSlips(dailyDeposit: DailyDeposit) {
    this.authService.selectedTenant.pipe(take(1)).subscribe((tenant) => {
      this.authService.refreshCookie();
      const myWindow = window.open(`/api/${tenant.tenantId}/deposit/${dailyDeposit.id}/depositSlips`, '_printWindow', 'width=600,height=600');
      myWindow.focus();
      myWindow.print();
    });
  }

  deleteDeposit(dailyDeposit: DailyDeposit) {
    this.dataservice.deleteDeposit(dailyDeposit);
  }

  unmarkAsProcessed(dailyDeposit: DailyDeposit) {
    this.dataservice.unmarkDepositAsProcessed(dailyDeposit);
  }

  markAsProcessed(dailyDeposit: DailyDeposit) {
    this.dataservice.markDepositAsProcessed(dailyDeposit);
  }

  processDeposit(dailyDeposit: DailyDeposit) {
    this.authService.selectedTenant.pipe(take(1)).subscribe((tenant) => {
      this.authService.refreshCookie();
      this.httpClient.post(`/api/${tenant.tenantId}/deposit/${dailyDeposit.id}/process`, {}, {}).subscribe((results) => {
        this.processing = true;
        this.dataservice.markDepositAsProcessed(dailyDeposit);
        this.toastr.success("Deposit has been processed in Xero", "Success")
      }, (error) => {
        this.processing = false;
        this.toastr.error(error, "Failed to process deposit")
      });
    });
  }

}
