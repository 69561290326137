import { Component, OnDestroy, OnInit } from '@angular/core';

import { PaymentType, PaymentMethod, IncomeAccount, PayTypeMapping, ClientConfiguration } from 'xero-dental-deposit-common'
import { DataService } from '../../../data.service';
import { UntypedFormBuilder, UntypedFormGroup, FormControl } from '@angular/forms';
import { filter, take } from 'rxjs/operators';
import { RefundMappingConfiguration, BankAccount, CheckGoesTo, AdjustmentType  } from 'xero-dental-deposit-common'
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { Account } from 'xero-dental-deposit-common'

@Component({
  selector: 'app-refund-mapping',
  templateUrl: './refund-mapping.component.html',
  styleUrls: ['./refund-mapping.component.css']
})

export class RefundMappingComponent implements OnInit {
  valueChangeSubscription: Subscription;
  saving = false;
  refundMappingForm: UntypedFormGroup;
  incomeAccounts: IncomeAccount[];
  bankAccounts: BankAccount[];
  paymentTypes: PaymentType[];
  refundMappings: RefundMappingConfiguration[];
  selectedAdjustmentType: AdjustmentType;
  adjustmentTypes: AdjustmentType[];
  checkGoesToValues: string[] = Object.keys(CheckGoesTo);
  _fb: UntypedFormBuilder;

  public compareFn(a: Account, b: Account) {
    return a && b ? a.id === b.id : a === b;
  }

  public onSubmit() {
    this.saving = true;
    const form = this.refundMappingForm.get('refundMapping');

    const refundMappingConfiguration = new RefundMappingConfiguration({
      paymentType: form.get('paymentType').value,
      adjustmentType: form.get('adjustmentType').value,
      bankAccount: form.get('bankAccount').value,
      checkGoesTo: form.get('checkGoesTo').value,
      incomeAccount: form.get('incomeAccount').value,
      issueCheck: form.get('refundType').value === 'refundCheck' ? true : false
    });

    if (form.get('refundType').value === 'none') {
      this.data.deleteRefundMapping(refundMappingConfiguration).then(() => {
        this.saving = false;
      });
    } else {
      this.data.setRefundMapping(refundMappingConfiguration).then(() => {
        this.saving = false;
      });
    }
  }

  public constructor(public data: DataService) {
    this._fb = new UntypedFormBuilder();
  }

  loadRefundMapping(refundMapping: RefundMappingConfiguration) {
    let refundType = 'none';

    if (refundMapping === undefined) {
        refundMapping = new RefundMappingConfiguration({
        bankAccount: this.bankAccounts[0],
        incomeAccount: this.incomeAccounts[0],
        issueCheck: false,
        paymentType: this.paymentTypes[0],
        checkGoesTo: CheckGoesTo.RESPONSIBLE_PARTY
      });
    } else {
      refundType = refundMapping.issueCheck ? 'refundCheck' : 'includeWithDeposit';
    }

    this.refundMappingForm.setControl('refundMapping', this._fb.group({
      adjustmentType: this.selectedAdjustmentType,
      refundType: refundType,
      issueCheck: refundMapping.issueCheck,
      bankAccount: refundMapping.bankAccount,
      incomeAccount: refundMapping.incomeAccount,
      checkGoesTo: refundMapping.checkGoesTo,
      paymentType: refundMapping.paymentType
    }));
  }

  ngOnInit() {
    this.refundMappingForm = this._fb.group({'adjustmentType': this._fb.control(null) });

    this.data.clientConfiguration.pipe(filter((x) => x !== undefined)).subscribe((clientConfiguration) => {

      this.paymentTypes = clientConfiguration.paymentTypes;
      this.incomeAccounts = clientConfiguration.incomeAccounts;
      this.bankAccounts = clientConfiguration.bankAccounts;
      this.adjustmentTypes = _.sortBy(clientConfiguration.adjustmentTypes, ['name']);
      this.refundMappings = clientConfiguration.refundMappings;

      if (this.selectedAdjustmentType === undefined) {
        this.selectedAdjustmentType = this.adjustmentTypes[0];
        this.refundMappingForm.setControl('adjustmentType', this._fb.control(this.selectedAdjustmentType));
      }

      const refundMapping = clientConfiguration.getRefundMappingByAdjustmentTypeId(this.selectedAdjustmentType.id);
      this.loadRefundMapping(refundMapping);
      if (this.valueChangeSubscription !== undefined) {
        this.valueChangeSubscription.unsubscribe();
      }

      this.valueChangeSubscription = this.refundMappingForm.get('adjustmentType').valueChanges.subscribe((newValue) => {
        this.selectedAdjustmentType = newValue;
        this.loadRefundMapping(clientConfiguration.getRefundMappingByAdjustmentTypeId(this.selectedAdjustmentType.id));
      });

    });
  }

}

