<ul class="nav nav-tabs justify-content-center">
  <li class="nav-item"> 
    <a class="nav-link" routerLinkActive="active" routerLink="/home">Home</a>
  </li>
  <li class="nav-item"> 
    <a class="nav-link" routerLinkActive="active" routerLink="deposits">Deposits</a>
  </li>
  <li ngbDropdown class="nav-item">
    <a href (click)="false" class="nav-link" [ngClass]="router.isActive('/setup', false) ? 'active': ''" ngbDropdownToggle>Configuration</a>
    <div ngbDropdownMenu>
      <button ngbDropdownItem routerLinkActive routerLink="/setup/xero">Xero Configuration</button>
      <button ngbDropdownItem routerLinkActive routerLink="/setup/paytypes">Configure Paytypes</button>
      <button ngbDropdownItem routerLinkActive routerLink="/setup/deposits">Configure Deposits</button>
      <button ngbDropdownItem routerLinkActive routerLink="/setup/refunds">Configure Refunds</button>
      <button ngbDropdownItem routerLinkActive routerLink="/setup/token">Client Token</button>
    </div>
  </li>
  <li class="navbar-nav me-auto mb-2 mb-lg-0">

  </li>
  <li ngbDropdown *ngIf="authService.isLoggedIn && availableTenants && availableTenants.length > 1" routerLinkActive="active" class="nav-item dropdown">
    <a ngbDropdownToggle class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
      aria-haspopup="true" aria-expanded="false">
      {{ (authService.selectedTenant | async).tenantName }}
    </a>
    <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="navbarDropdown">
      <a *ngFor="let tenant of availableTenants" class="dropdown-item" (click)="authService.switchTenant(tenant)">{{tenant.tenantName}}</a>
    </div>
  </li>
  <li>
    <a class="nav-link" *ngIf="!authService.isLoggedIn" (click)="authService.login()">Login</a>
  </li>
  <li>
    <a class="nav-link" *ngIf="authService.isLoggedIn" (click)="authService.logout()">Logout</a>
  </li>
</ul>
<!-- 

<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" href="#">BRAND</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class=" navbar-collapse collapse justify-content-between" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" routerLink='/home' routerLinkActive="active">Home</a>
      </li>

      <li class="nav-item">
        <a class="nav-link" *ngIf="authService.isLoggedIn" routerLink='/deposits' routerLinkActive="active">Deposits</a>
      </li>

      <li ngbDropdown *ngIf="authService.isLoggedIn" routerLinkActive="active" class="nav-item dropdown">
        <a ngbDropdownToggle class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          Configuration
        </a>
        <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" routerLink="/setup/xero">Xero Configuration</a>
          <a class="dropdown-item" routerLink="/setup/paytypes">Configure Paytypes</a>
          <a class="dropdown-item" routerLink="/setup/deposits">Configure Deposits</a>
          <a class="dropdown-item" routerLink="/setup/refunds">Configure Refunds</a>
        </div>
      </li>
    </ul>
    <ul class="navbar-nav">
      <li ngbDropdown *ngIf="authService.isLoggedIn && authService.hasMultipleUsers" routerLinkActive="active" class="nav-item dropdown">
        <a ngbDropdownToggle class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          {{ (authService.selectedAccount | async).tenant }}
        </a>
        <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a *ngFor="let tenant of (authService.availableTenants | async)" class="dropdown-item" (click)="authService.switchTenant(tenant)">{{tenant.tenantName}}</a>
        </div>
      </li>

      <li>
        <a class="nav-link" *ngIf="!authService.isLoggedIn" (click)="authService.login()">Login</a>
      </li>
      <li>
        <a class="nav-link" *ngIf="authService.isLoggedIn" (click)="authService.logout()">Logout</a>
      </li>
    </ul>
  </div>
</nav> -->