<div class="row action-column">
  <app-deposit-action-component [showMove]="false" [showDelete]="false" [deposit]="dailyDeposit"></app-deposit-action-component>
</div>
<ul ngbNav #nav="ngbNav" class="nav-tabs">
  <li [ngbNavItem]="1">
    <a ngbNavLink>Payments</a>
    <ng-template ngbNavContent>
      <form *ngIf="paymentForm" [formGroup]="paymentForm">
        <div *ngFor="let payment of paymentForm.controls.payments.controls let i = index">
          <div formArrayName="payments">
            <div class="row" [formGroupName]="i">
              <div class="col-6">
                {{ payment.value.description }}
              </div>
              <div class="col-1">
                {{ payment.value.amount | currency }}
              </div>
              <div class="col-5">
                <select formControlName="paymentTypeId">
                  <option *ngFor="let paymentType of paymentTypes" [ngValue]="paymentType.id">{{paymentType.name}}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ng-template>
  </li>
  <li [ngbNavItem]="2">
    <a ngbNavLink>Deposits</a>
    <ng-template ngbNavContent>
      <h2>Deposits</h2>
      <div *ngFor="let config of configurations; let i = index">
        <div *ngFor="let deposit of dailyDeposit.depositMap.get(config)">
          <div class="row">
            <div class="col deposit-header">
              {{ config.memo }} ({{ deposit.depositLines.length }} items
              total)
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">Name</div>
            <div class="col-md-7">Memo</div>
            <div class="col-md-2">Check Number</div>
            <div class="col-md-1">Amount</div>
          </div>

          <div class="row" *ngFor="let depositLine of deposit.depositLines">
            <div class="col-md-2">{{ depositLine.paymentMethod.name }}</div>
            <div class="col-md-7">{{ depositLine.memo }}</div>
            <div class="col-md-2">{{ depositLine.checkNumber }}</div>
            <div class="col-md-1">{{ depositLine.amount | currency }}</div>
          </div>

          <div class="row lastrow" >
            <div class="col-md-2"></div>
            <div class="col-md-7"></div>
            <div class="col-md-2"></div>
            <div class="col-md-1">{{ deposit.getDepositTotal() | currency }} </div>
          </div>
        </div>
      </div>

      <h2>Refunds To be Issued</h2>
      <div *ngIf="dailyDeposit.checksToWrite.length == 0">No Refunds on this date</div>
      <div *ngIf="dailyDeposit.checksToWrite.length > 0">
        <div class="row">
          <div class="col">Recipient</div>
          <div class="col">Memo</div>
          <div class="col">Amount</div>
        </div>
        <div class="row" *ngFor="let checkToWrite of dailyDeposit.checksToWrite">
          <div class="col">
            {{ checkToWrite.fullName }}<br />
            {{ checkToWrite.address1 }}<br />
            <span *ngIf="checkToWrite.address2">{{ checkToWrite.address2 }}<br /></span>
            {{ checkToWrite.city }}, {{ checkToWrite.state }} {{ checkToWrite.zipcode}}
          </div>
          <div class="col">
            {{ checkToWrite.memo }}
          </div>
          <div class="col">
            {{ checkToWrite.amount | currency }}
          </div>
        </div>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="3" ngbNavItem="xero-transactions" [disabled]="completedTransactions.length == 0">
    <a ngbNavLink>Completed Xero Transactions</a>
    <ng-template ngbNavContent *ngIf="!completedTransactions || completedTransactions.length == 0">
      No completed transactions found in Xero.
    </ng-template>
    <ng-template ngbNavContent *ngIf="completedTransactions && completedTransactions.length > 0">
      <div class="row">
        <div class="col">Type</div>
        <div class="col">Memo</div>
        <div class="col">Amount</div>
        <div class="col">Link</div>
      </div>
      <div class="row" *ngFor="let completedTransaction of completedTransactions">
        <div class="col">
          {{ completedTransaction.type }}
        </div>
        <div class="col">
          {{ completedTransaction.memo }}
        </div>
        <div class="col">
          {{ completedTransaction.amount | currency }}
        </div>
        <div class="col">
          <a target="xero-window" [href]="linkForTransaction(completedTransaction.transactionId) | async">View
            Transaction</a>
        </div>
      </div>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>