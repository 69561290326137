import { DepositLine } from './BasicTypes.js';
/**
 * This represents a single deposit. This deposit will contain one or more deposit lines.
 *
 */
export class Deposit {
    constructor(deposit) {
        this.depositLines = [];
        Object.assign(this, deposit);
    }
    getDepositTotal() {
        let total = 0;
        for (let line of this.depositLines) {
            total += line.amount;
        }
        return total;
    }
    getCount(paymentMethod) {
        var i = 0;
        this.depositLines.forEach((depositLine) => {
            if (depositLine.paymentMethod.id === paymentMethod.id)
                i++;
        });
        return i;
    }
    needsDepositSlip() {
        for (const depositLine of this.depositLines) {
            if (depositLine.paymentMethod.name.toLowerCase() === "cash" || depositLine.paymentMethod.name.toLowerCase() === "check")
                return true;
        }
        return false;
    }
    addLine(depositLine) {
        this.depositLines.unshift(depositLine);
    }
    static fromJSON(depositJSON) {
        const deposit = new Deposit({ depositToAccount: depositJSON.contact, memo: depositJSON.memo, contact: depositJSON.contact });
        depositJSON.depositLines.forEach((json) => { deposit.addLine(DepositLine.fromJSON(json)); });
        return deposit;
    }
    toString() {
        var str = `Deposit: memo='${this.memo}', contact='${this.contact.name}', depositToAccount='${this.depositToAccount.name}'`;
        this.depositLines.forEach((line) => {
            str += `\n -> ${line.toString()}`;
        });
        return str;
    }
}
